import { Job } from 'generated/apolloComponents'
import style from './JobListItem.module.scss'
import JobPosition from './JobPosition'

interface IProps {
  job: Job
}

const JobListItemTitle = ({ job }: IProps) => (
  <div className={`${style.item__title}`}>
    <h3>
      <JobPosition position={job.position as string} />
      {job.highlight && (
        <span className='hidden md:inline-block ml-3 bg-green-500 text-xxs font-semibold leading-4 text-black px-2 py-1 rounded-sm align-middle'>
          Special Offer
        </span>
      )}
    </h3>
    <div className='mt-2 flex items-center'>
      <div className='text-xxs uppercase text-neutral-800 font-semibold tracking-wider'>
        {job.company.name}
      </div>
    </div>
  </div>
)

export default JobListItemTitle
