import { Candidate, Job } from 'generated/apolloComponents'
import style from './JobListItem.module.scss'
import Salary from '@/components/atoms/JobListItem/Salary'

interface IProps {
  job: Job
  me?: Candidate
  minSalaryFilter?: number | null
}

const JobListItemSalary = ({ job, me, minSalaryFilter }: IProps) => (
  <div className={`${style.item__salary}`}>
    <Salary job={job} me={me} minSalaryFilter={minSalaryFilter} />
  </div>
)

export default JobListItemSalary
