interface IProps {
  data: string[] | undefined
  limit: number
}

const SkillsPills = ({ data, limit }: IProps) => (
  <div className='flex flex-wrap justify-end gap-3'>
    {data?.slice(0, limit).map((technology) => (
      <span
        className='py-2 px-3 bg-neutral-200 rounded-sm font-medium text-xs text-neutral-700'
        key={technology}
      >
        {technology}
      </span>
    ))}
  </div>
)
export default SkillsPills
