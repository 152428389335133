import { Candidate, Job } from 'generated/apolloComponents'
import { Dispatch, SetStateAction } from 'react'
import style from './JobListItem.module.scss'
import ToggleSaveOffer from '@/components/atoms/ToggleSaveOffer'

interface IProps {
  job: Job
  me?: Candidate
  isLiked?: boolean
  setIsLiked: Dispatch<SetStateAction<boolean>>
}

const JobListItemSave = ({ job, me, isLiked, setIsLiked }: IProps) => (
  <div className={`${style.item__save}`}>
    <ToggleSaveOffer
      jobId={job.id}
      likedJobs={me?.likedJobs! || []}
      liked={isLiked}
      setIsLiked={setIsLiked}
    />
  </div>
)

export default JobListItemSave
