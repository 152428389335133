interface IProps {
  position: string
}

const JobPosition = ({ position }: IProps) => {
  if (position.includes('/')) {
    return (
      <>
        {position.split('/').map((word, i) => (
          <>
            {i > 0 && (
              <>
                /<wbr />
              </>
            )}
            {word}
          </>
        ))}
      </>
    )
  }

  return <>{position}</>
}

export default JobPosition
