import { Candidate, Job } from 'generated/apolloComponents'
import useTranslation from 'next-translate/useTranslation'
import NoSalary from '../NoSalary'

interface IProps {
  job: Job
  me?: Candidate
  minSalaryFilter?: number | null
}

const Salary = ({ job, me, minSalaryFilter }: IProps) => {
  const { t } = useTranslation('common')

  if (!job.showSalary && !job.hiddenBrackets) {
    return <div />
  }

  return (
    <div className='lg:font-extrabold md:text-xl text-dm leading-8'>
      {job.showSalary ? (
        <>
          {job.denominatedSalaryLong.money} {job.denominatedSalaryLong.currency}
        </>
      ) : (
        <NoSalary
          job={job}
          mySalary={!!me?.minSalary}
          minSalaryFilter={minSalaryFilter}
        />
      )}
    </div>
  )
}

export default Salary
