import { MouseEvent, useState } from 'react'
import {
  ChevronUpIcon,
  MapPinIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/solid'

interface iProps {
  list: string[]
  title: string
  icon?: boolean
  className?: string
  textSize?: string
}

const DropdownString = ({
  title,
  icon,
  list,
  className = '',
  textSize = 'xs',
}: iProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleClick = (e: MouseEvent) => {
    e.preventDefault()
    setOpen(!open)
  }

  const buttonColor = className?.includes('text-white') ? 'transparent' : ''

  return (
    <div
      className={`relative rounded-t-lg ${
        open && 'shadow-dropdown z-10'
      } ${className}`}
    >
      {list.length > 1 ? (
        <button
          type='button'
          className={`flex items-center w-full relative text-${textSize} ${buttonColor}`}
          onClick={handleClick}
        >
          {icon && <MapPinIcon className='w-6 mr-1 text-neutral-600' />}

          {title}
          {open ? (
            <ChevronUpIcon className='w-4 ml-1 text-neutral-600 font-normal' />
          ) : (
            <ChevronDownIcon className='w-4 ml-1 text-neutral-600 font-normal' />
          )}
        </button>
      ) : (
        <>
          <MapPinIcon className='w-6 mr-2 text-neutral-600' />
          <span className={`text-${textSize}`}>{list[0]}</span>
        </>
      )}

      {list.length > 1 && (
        <div
          className={`${
            open ? 'visible' : 'hidden'
          }  absolute left-0 bg-white w-full shadow-dropdown rounded-b-lg`}
        >
          <div className={`px-1 py-3 ${icon && 'ml-4'}`}>
            {list
              .map((str) => str.trim())
              .map((item: string, index: number) => (
                <span
                  key={index}
                  className={` group flex rounded-md items-center w-full px-2 my-1 font-normal`}
                >
                  {item}
                </span>
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default DropdownString
