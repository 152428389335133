import { Candidate, Job } from 'generated/apolloComponents'
import useTranslation from 'next-translate/useTranslation'
import { Dispatch, SetStateAction } from 'react'
import Link from 'next/link'
import JobListItemLogo from './JobListItemLogo'
import JobListItemDetails from './JobListItemDetails'
import style from './JobListItem.module.scss'
import JobListItemTitle from './JobListItemTitle'
import JobListItemSalary from './JobListItemSalary'
import JobListItemTags from './JobListItemTags'
import JobListItemSave from './JobListItemSave'

interface iProps {
  job: Job
  me?: Candidate
  isVisible?: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
  isLiked?: boolean
  setIsLiked: Dispatch<SetStateAction<boolean>>
  minSalaryFilter?: number | null
}

const JobListItem = ({
  job,
  me,
  isVisible,
  setIsVisible,
  isLiked,
  setIsLiked,
  minSalaryFilter,
}: iProps) => {
  const { t } = useTranslation('common')

  return (
    <Link
      href={`/companies/jobs/${job.id}`}
      prefetch={false}
      className={style.item}
      data-cy='offer-card'
    >
      <JobListItemLogo job={job} />
      <JobListItemTitle job={job} />
      <JobListItemDetails job={job} />
      <JobListItemSalary job={job} />
      <JobListItemTags job={job} />
      <JobListItemSave job={job} isLiked={isLiked} setIsLiked={setIsLiked} />
    </Link>
  )
}

export default JobListItem
