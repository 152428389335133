import useTranslation from 'next-translate/useTranslation'
import { useSession } from 'next-auth/react'
import {
  BaseSyntheticEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import {
  DislikeOfferMutation,
  HideInput,
  LikeOfferMutation,
  useDislikeOfferMutation,
  useLikeOfferMutation,
} from 'generated/apolloComponents'
import Link from 'next/link'
import { HeartIcon } from '@heroicons/react/24/solid'
import { useRouter } from 'next/router'
import Tippy from '@tippyjs/react'
import { useMe } from '@/context/meContext'

import 'tippy.js/dist/tippy.css' // optional

type Variables = {
  input: HideInput
}

interface IProps {
  jobId: string
  likedJobs: string[]
  liked?: boolean
  setIsLiked: Dispatch<SetStateAction<boolean>>
}

const ToggleSaveOffer = ({
  jobId,
  likedJobs,
  liked = false,
  setIsLiked,
}: IProps) => {
  const { t, lang } = useTranslation('common')
  const { data: session } = useSession()
  const [expanded, setExpanded] = useState(false)
  const router = useRouter()
  const { loadMe } = useMe()

  const expand = (e: BaseSyntheticEvent) => {
    e.preventDefault()
    setExpanded(true)
  }

  const close = () => {
    setTimeout(() => {
      setExpanded(false)
    }, 200)
  }

  const variables: Variables = {
    input: {
      jobId: jobId,
    },
  }

  const [likeOffer] = useLikeOfferMutation({
    onCompleted: (data: LikeOfferMutation) => {
      data.likeOffer?.success && setIsLiked(true)
      loadMe(true)
    },
  })

  const [dislikeOffer] = useDislikeOfferMutation({
    onCompleted: (data: DislikeOfferMutation) => {
      data.dislikeOffer?.success && setIsLiked(false)
      loadMe(true)
    },
  })

  const handleLikeClick = async (e: BaseSyntheticEvent) => {
    e.preventDefault()

    if (session) likeOffer({ variables: variables })
  }

  const handleDislikeClick = async (e: BaseSyntheticEvent) => {
    e.preventDefault()

    if (session) dislikeOffer({ variables: variables })
  }

  return (
    <div className='absolute right-1 top-1 md:relative lg:h-full flex items-center'>
      {expanded && !session && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className='opaque absolute -bottom-2 -right-3 rounded-md bg-white font-normal w-96 py-6 px-6 text-sm pb-16 z-20 mb-0.5'
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          <p className='text-lg mb-3'>
            <strong>{t('common:save_offer')}</strong>
          </p>
          <p className='mb-3'>
            {t('common:save_instruction')}{' '}
            <Link
              href={`/auth/login?redirect=${encodeURIComponent(router.asPath)}`}
              prefetch={false}
              className='font-medium underline hover:no-underline'
            >
              {t('common:login')}
            </Link>
          </p>
          <p>
            {t('common:dont_have_account')}{' '}
            <Link
              href={`/auth/register?redirect=${encodeURIComponent(
                router.asPath
              )}`}
              prefetch={false}
              className='font-medium underline hover:no-underline'
            >
              {t('common:create_it')}
            </Link>{' '}
            {t('common:stop_looking_job')}
          </p>
          <svg
            className='text-neutral-400 hover:text-primary-hover transition-all'
            width='24'
            height='24'
            fill='currentColor'
          >
            <use href='#heroicon-heart-icon' />
          </svg>
        </div>
      )}

      <Tippy content={t('common:save_offer')}>
        <button
          type='button'
          className='md:p-4'
          aria-label={t('common:save_offer')}
          onClick={(e) => {
            e.preventDefault()
            expand(e)
            liked ? handleDislikeClick(e) : handleLikeClick(e)
          }}
          onBlur={close}
        >
          <svg
            className={`mx-auto inline-block hover:text-primary-hover transition-all ${
              liked ? 'text-primary' : 'text-neutral-400'
            }`}
            width='24'
            height='24'
            fill='currentColor'
          >
            <use href='#heroicon-heart-icon' />
          </svg>
        </button>
      </Tippy>
    </div>
  )
}

export default ToggleSaveOffer
