import { Job } from 'generated/apolloComponents'
import style from './JobListItem.module.scss'

interface IProps {
  job: Job
}

const JobListItemLogo = ({ job }: IProps) => (
  <div className={`${style.item__logo}`}>
    <img
      src={job.company.logo?.url as string}
      className='w-full h-full object-contain'
      width={96}
      height={96}
      loading='lazy'
      alt={job.company.name}
    />
  </div>
)

export default JobListItemLogo
