import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { Candidate, Job } from '../../../generated/apolloComponents'
import JobListItemHidden from './JobListItemHidden'
import JobListItem from './JobListItem'

interface iProps {
  job: Job
  me?: Candidate
  minSalaryFilter?: number | null
}

const JobListItemWrapper = ({
  job,
  me = undefined,
  minSalaryFilter,
}: iProps) => {
  const { t } = useTranslation('common')
  const [isVisible, setIsVisible] = useState<boolean>(
    !me?.rejectedJobs?.includes(job.id.split('-')[0])
  )

  const [isLiked, setIsLiked] = useState<boolean>(
    me?.likedJobs?.includes(job.id.split('-')[0]) ?? false
  )

  useEffect(() => {
    setIsLiked(me?.likedJobs?.includes(job.id.split('-')[0]) as boolean)
  }, [me])

  useEffect(() => {
    setIsVisible(!me?.rejectedJobs?.includes(job.id.split('-')[0]) as boolean)
  }, [me])

  const {
    query: { slug },
  } = useRouter()

  const showRejected = ['rejected', 'applied', 'liked'].includes(slug as string)

  return !isVisible && !showRejected ? (
    <JobListItemHidden
      job={job}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
    />
  ) : (
    <JobListItem
      job={job}
      me={me}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isLiked={isLiked}
      setIsLiked={setIsLiked}
      minSalaryFilter={minSalaryFilter}
    />
  )
}

export default JobListItemWrapper
