/* eslint-disable no-shadow */
import { Job } from 'generated/apolloComponents'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import Tippy from '@tippyjs/react'

import 'tippy.js/dist/tippy.css'

interface IXProps {
  isSalaryFilter: boolean
  matchingUserBrackets: boolean
  minSalaryKnown: boolean | undefined
}

export const SalaryKnown = ({
  isSalaryFilter,
  matchingUserBrackets,
  minSalaryKnown,
}: IXProps) => {
  const { t } = useTranslation('common')

  const knownOrMatching = ({
    minSalaryKnown,
    isSalaryFilter,
    matchingUserBrackets,
  }: IXProps) => {
    if (minSalaryKnown) {
      if (isSalaryFilter || matchingUserBrackets) {
        return t('common:brackets_matching')
      }
      return t('common:brackets_not_matching')
    }
    if (isSalaryFilter) {
      return t('common:brackets_matching')
    }
    return t('common:brackets_known')
  }

  return (
    <>
      {knownOrMatching({
        minSalaryKnown,
        isSalaryFilter,
        matchingUserBrackets,
      })}
    </>
  )
}

interface IProps {
  job: Job
  [x: string]: any
  minSalaryFilter?: number | null
  mySalary?: boolean
}

const NoSalary = ({ job, minSalaryFilter, mySalary, ...rest }: IProps) => {
  const router = useRouter()
  const { t, lang } = useTranslation('common')

  const [inputProps, setInputProps] = useState<any>('')
  const [isSalaryFilter, setIsSalaryFilter] = useState<boolean>(
    minSalaryFilter ? minSalaryFilter !== 0 : false
  )
  const [tooltipText, setTooltipText] = useState<string>('')

  useEffect(() => {
    const filterSet = minSalaryFilter ? minSalaryFilter !== 0 : false
    setIsSalaryFilter(filterSet)
    setTooltipText(
      filterSet
        ? t('common:brackets_hidden_known')
        : t('common:brackets_hidden_desc')
    )
  }, [minSalaryFilter])

  return (
    <div {...rest} className='relative whitespace-nowrap'>
      <p
        className={`${
          job.hiddenBrackets ? 'text-action-500' : 'text-gray-300'
        }`}
      >
        {job.hiddenBrackets && (
          <SalaryKnown
            isSalaryFilter={isSalaryFilter}
            matchingUserBrackets={job.matchingUserBrackets}
            minSalaryKnown={mySalary}
          />
        )}
        {job.hiddenBrackets ? (
          <Tippy content={tooltipText}>
            <span
              className='cursor-pointer '
              data-padding='16px 16px'
              data-for='tooltip'
              data-tip
              data-class='ml-10 w-72 shadow-dropdown font-normal text-sm '
              {...inputProps}
            >
              <InformationCircleIcon
                width={15}
                className='ml-2 mb-1 text-action-500 hover:text-action-300 transition-all'
                style={{ maxWidth: '14px', maxHeight: '14px' }}
              />
            </span>
          </Tippy>
        ) : null}
      </p>
    </div>
  )
}

export default NoSalary
