import { Job } from 'generated/apolloComponents'
import style from './JobListItem.module.scss'
import SkillsPills from '@/components/atoms/JobListItem/SkillsPills'

interface IProps {
  job: Job
}

const JobListItemTags = ({ job }: IProps) => (
  <div className={`${style.item__tags}`}>
    <SkillsPills data={job.technologyTags as string[]} limit={3} />
  </div>
)

export default JobListItemTags
