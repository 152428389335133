import { Job } from 'generated/apolloComponents'
import useTranslation from 'next-translate/useTranslation'
import { Dispatch, SetStateAction } from 'react'
import ToggleVisibleOffer from '@/components/atoms/ToggleVisibleOffer'

interface iProps {
  job: Job
  isVisible?: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
}

const JobListItemHidden = ({ job, isVisible, setIsVisible }: iProps) => {
  const { t } = useTranslation('common')

  return (
    <div className='py-6 md:py-4 px-8 flex flex-wrap relative bg-white mb-2 rounded-lg shadow items-center'>
      <h3 className='text-c20 font-medium'>{job.position}</h3>
      <p className='ml-auto text-sm font-normal mr-8'>
        {t('common:hidden_offer_in')}{' '}
        <a href='/account/jobs/rejected' className='font-medium underline'>
          {t('common:your_profile')}
        </a>
      </p>
      <ToggleVisibleOffer
        jobId={job.id}
        setIsVisible={setIsVisible}
        visible={isVisible}
      />
    </div>
  )
}

export default JobListItemHidden
